@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

.font-poppins {
  font-family: 'Poppins', sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: none;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #fff4;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  /*  width: 12px; */
  width: 0px;
  background: transparent;
}

*::-webkit-scrollbar-track {
  background: #fff0;
}

*::-webkit-scrollbar-thumb {
  background-color: #fff4;
}

* ::-webkit-scrollbar-thumb:hover {
  background: #5555;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Poppins', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
